import React from 'react'
import { useParams } from 'react-router-dom';
//import ProjectItem from '../components/ProjectItem';
import { ProjectList } from '../helpers/ProjectList';
import GitHubIcon from '@material-ui/icons/GitHub';
import '../Styles/ProjectDisplay.css'

function ProjectDisplay() {
  const { id } = useParams();
  const project = ProjectList[id];
  return (
    <div className='project'>
        <h1> {project.name}</h1>
        <img alt='' src={project.image} />
        <p>
            <b></b>Skills: {project.skills}
        </p>
        <GitHubIcon />
    </div>
  )
}

export default ProjectDisplay 
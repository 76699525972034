import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import StarIcon from '@material-ui/icons/Star';

export default function Experience() {
  return (
        <div className='experience'>
        <VerticalTimeline lineColor='#233554'>
        <VerticalTimelineElement 
            className="vertical-timeline-element--education"
            contentStyle={{ background: '#F0F0F0', color: '#233554' }}
            contentArrowStyle={{ borderRight: '7px solid  #F0F0F0' }}
            date="2010 - 2014"
            iconStyle={{ background: '#233554', color: '#fff' }}
            icon={<SchoolIcon />}
          > <h3 className="vertical-timeline-element-title">HAN university of applied sciences, Arnhem</h3>
            <p>BSc - Bachelor of Science</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement 
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#F0F0F0', color: '#233554' }}
            contentArrowStyle={{ borderRight: '7px solid  #F0F0F0' }}         
            date="2014 - 2017"
            iconStyle={{ background: '#8892b0', color: '#0a192f' }}
            icon={<WorkIcon />}
          > <h3 className='vertical-timeline-element-title'>CGI / KPN / etc</h3>
            <p>Product Owner</p>
        </VerticalTimelineElement>        
        <VerticalTimelineElement 
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#F0F0F0', color: '#233554' }}
            contentArrowStyle={{ borderRight: '7px solid  #F0F0F0' }}          
            date="2017 - 2020"
            iconStyle={{ background: '#8892b0', color: '#0a192f' }}
            icon={<WorkIcon />}
          > <h3 className='vertical-timeline-element-title'>Easy Systems</h3>
            <p>Technical Consultant and Team lead</p>
        </VerticalTimelineElement>      
        <VerticalTimelineElement 
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#F0F0F0', color: '#233554' }}
            contentArrowStyle={{ borderRight: '7px solid  #F0F0F0' }} 
            date="2021 - 2022"
            iconStyle={{ background: '#8892b0', color: '#0a192f' }}             
            icon={<WorkIcon />}
          > <h3 className='vertical-timeline-element-title'>Navara / Triodos</h3>
            <p>Product Owner and Analist</p>
        </VerticalTimelineElement>            
        <VerticalTimelineElement 
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#F0F0F0', color: '#233554' }}
            contentArrowStyle={{ borderRight: '7px solid  #F0F0F0' }}            
            date="2022 - present"
            iconStyle={{ background: '#8892b0', color: '#0a192f' }}
            icon={<WorkIcon />}
          > <h3 className='vertical-timeline-element-title'>Navara / PWN</h3>
            <p>Product Owner / Project Manager</p>
        </VerticalTimelineElement>   
        <VerticalTimelineElement 
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#F0F0F0', color: '#233554' }}
            contentArrowStyle={{ borderRight: '7px solid  #F0F0F0' }}            
            date="2022 - present"
            iconStyle={{ background: '#8892b0', color: '#0a192f' }}
            icon={<WorkIcon />}
          > <h3 className='vertical-timeline-element-title'>Navara / Belastingdienst</h3>
            <p>Solution Architect / Scrum Master / PO</p>
        </VerticalTimelineElement>           
        <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#0a192f' }}
          icon={<StarIcon />}
        />     
      </VerticalTimeline>
    </div>
  )
}

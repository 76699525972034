import React from 'react'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import "../Styles/Footer.css"
let CurrentYear = new Date().getFullYear();

function Footer() {
  return (
    <div className='footer'>
        <div className='SocialMedia'>
            <InstagramIcon />
            <TwitterIcon />
            <FacebookIcon />
            <LinkedInIcon />
        </div>
        <p>&copy; {CurrentYear} frankvanroekel.com</p>
    </div>
  )
}

export default Footer
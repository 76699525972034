
import Proj1 from "../assets/proj1.jpg";
import Proj2 from "../assets/proj2.jpg";
import Proj3 from "../assets/proj3.jpg";
import Proj4 from "../assets/proj4.jpg";
import Proj5 from "../assets/proj5.jpg";
import Proj6 from "../assets/proj6.png";
import Proj7 from "../assets/proj7.jpg";

export const ProjectList = [
    {
      name: "Solution Architect, Scrum Master - Belastingdienst",
      image: Proj7,
      skills: "Bitbucket, Java, Bitbucket, Backlog refinement, Scum Master",
    },    
    {
      name: "Product Owner - Triodos online banking",
      image: Proj1,
      skills: "Bitbucket, DevOps, Java, Backlog refinement, Writing user stories, Stakeholder management",
    },
    {
      name: "Product Owner - PWN online environments",
      image: Proj2,
      skills: "Azure DevOps, Angular, .NET, Project management",
    },
    {
      name: "Analist - KPN Datawarehousing",
      image: Proj3,
      skills: "Database management, SQL, Reporting, .NET",
    },
    {
      name: "Product Owner - KPN Security tool",
      image: Proj4,
      skills: ".NET, Backlog refinement, stakeholder management, SQL, Reporting",
    },
    {
      name: "Developer/Analist - CGI Various projects",
      image: Proj5,
      skills: "Java, JavaScript, HTML, CSS",
    },
    {
      name: "Consultant - Easy Systems e-invoicing",
      image: Proj6,
      skills: "AWS, SQL, .NET, Windows Server, Git",
    },
  ];
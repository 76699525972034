import React from 'react';
import ProjectItem from '../components/ProjectItem.js';
import '../Styles/Projects.css';
import { ProjectList } from '../helpers/ProjectList.js';

export default function Projects() {
  return (
    <div className='projects'>
      <h1>My personal projects</h1>
      <div className='projectList'>
        {ProjectList.map((project, idx) => {
            return (
              <ProjectItem id={idx} name={project.name} image={project.image}/>
            )
          }
        )}
      </div>
    </div>
  )
} 
import React, { useEffect, useState } from 'react'
import {Link, useLocation} from 'react-router-dom'
import '../Styles/NavBar.css'
import { Reorder } from '@material-ui/icons'

function NavBar() {
    
    const [expandNavbar, setExpandNavbar] = useState(false);
    const location = useLocation();
  
    useEffect(() => {
            setExpandNavbar(false);
        },[location]
    )

    return (
    <div className='navbar' id={expandNavbar ? "open" : "closed"}> 
        <div className='toggleButton'>
            <button 
                onClick={() => {
                        setExpandNavbar((prev) => !prev);
                    }}
            > 
                <Reorder /> 
            </button>
        </div>
        <div className='links'>
            <Link to="/"> 01. home </Link>
            <Link to="/projects"> 02. projects </Link>
            <Link to="/experience"> 03. experience </Link>
        </div>
    </div>
  )
}

export default NavBar;
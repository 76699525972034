import React from 'react'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import EmailIcon from '@material-ui/icons/Email'
import GithubIcon from '@material-ui/icons/GitHub'
//import { IoIosRocket } from 'react-icons/io';
import "../Styles/Home.css"

export default function Home() {
  return (
    <div className='home'>
      <div className='about'>
        <h2>Hi, my name is Frank</h2>
        <div className='prompt'> 
          <p> 
            I'm a product owner and analist (and occasionally developer) focussed on buidling great solutions. 
            Currently I'm working on awesome projects at <a href="https://www.navara.nl" target='_blank' rel="noreferrer" >Navara</a>.
          </p>
          <LinkedInIcon />
          <EmailIcon />
          <GithubIcon />
        </div>
      </div>
      <div className='skills'>
        <h1>Skills</h1>
        <ol className='list'>
        <li className='item'>
            <h2>Tools and techniques</h2>
            <span>
              PSPO, Scrum, (Azure)DevOps, Backlog mngt, Writing user stories  
            </span>
          </li>
          <li className='item'>
            <h2>Frond-end</h2>
            <span>
              React, Angular, CSS, HTML, TypeScript, Javascript, NPM
            </span>
          </li>
          <li className='item'>
            <h2>Back-end</h2>
            <span>
              Java, .NET, MS SQL, AWS
            </span>
          </li>
        </ol>
      </div>
    </div>
  )
}
